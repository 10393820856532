import { Bar } from "react-chartjs-2";
import colors from "tailwindcss/colors";
import { Respondent } from "../../types";
import Tooltip from "../../components/Tooltip";
import { BarElement, ChartData } from "chart.js";
import { Chart as ChartJS } from "chart.js";
import { useEffect, useState } from "react";
import { PercentBadgeIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import Toggle from "../../components/Toggle";
import customIcons from "../../assets/customIcons";
import PercentSignIcon from "../../assets/customIcons/percent-svgrepo-com.svg";

ChartJS.register(BarElement);

export default function RespondentDistribution(props: { respondents: Array<Respondent> }) {
  const { respondents } = props;

  const [categoryDescription, setCategoryDescription] = useState("Hover over each bar to see the number of individuals and what they are experiencing");
  const [color, setColor] = useState("");
  const [barCount, setBarCount] = useState(0);
  const [calcType, setCalcType] = useState<"Count" | "Percentage">("Count");

  const labels: Array<string> = ["High Red", "Mid Red", "Low Red", "High Yellow", "Mid Yellow", "Low Yellow", "High Green", "Mid Green", "Disengaged"];
  let chartData: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0];

  for (let i = 0; i < respondents.length; i++) {
    let siValue = parseFloat(respondents[i].mental_health_value);

    if (siValue > 88 && siValue <= 100) {
      chartData[0]++;
    }
    if (siValue > 77 && siValue <= 88) {
      chartData[1]++;
    }
    if (siValue > 66 && siValue <= 77) {
      chartData[2]++;
    }
    if (siValue > 55 && siValue <= 66) {
      chartData[3]++;
    }
    if (siValue > 44 && siValue <= 55) {
      chartData[4]++;
    }
    if (siValue > 33 && siValue <= 44) {
      chartData[5]++;
    }
    if (siValue > 22 && siValue <= 33) {
      chartData[6]++;
    }
    if (siValue > 10 && siValue <= 22) {
      chartData[7]++;
    }
    if (siValue <= 10) {
      chartData[8]++;
    }
  }

  if (calcType === "Percentage") {
    const totalRespondents = chartData.reduce((a, b) => a + b, 0);
    for (let i = 0; i < chartData.length; i++) {
      chartData[i] = chartData[i] / totalRespondents;
    }
  }

  const colorRed = colors.red[300];
  const colorYellow = colors.yellow[300];
  const colorGreen = colors.green[400];
  const colorGray = colors.gray[300];

  const data: ChartData<"bar", number[], string> = {
    labels,
    datasets: [
      {
        label: "Count",
        data: chartData,
        backgroundColor: [colorRed, colorRed, colorRed, colorYellow, colorYellow, colorYellow, colorGreen, colorGreen, colorGray],
      },
    ],
  };

  function generateTooltip(tooltipItems: any): string {
    const label = tooltipItems[0].label;
    let color = "";
    let text = "";
    switch (label) {
      case labels[8]: {
        text = `Disconnection and Underperformance
- Individuals exhibiting a disconnection from their job or workplace`;
        color = colors.gray[400];
        break;
      }
      case labels[7]:
        text = `Positive Engagement and Potential for Growth
- Individuals engaged and actively seek ways to contribute to their own success and that of the organization`;
        color = colorGreen;
        break;
      case labels[6]:
        text = `Optimal Performance and Healthy Stress Levels
- Individuals consistently putting forth effort and experiencing appropriate levels of stress`;
        color = colorGreen;
        break;
      case labels[5]:
        text = `Occasional Stress Impacting Performance
- Individuals occasionally experiencing stress that interferes with decision-making`;
        color = colorYellow;
        break;
      case labels[4]:
        text = `Routine Stress Affecting Performance
- Individuals routinely experiencing stress that impacts performance, concentration, focus, and decision-making`;
        color = colorYellow;
        break;
      case labels[3]:
        text = `Distressing Levels of Stress
- Individuals experiencing distressing levels of stress that significantly impact overall functioning`;
        color = colorYellow;
        break;
      default:
        text = `Stress and Dysfunction
- Individuals experiencing extreme levels of stress and dysfunction (requires immediate attention)`;
        color = colorRed;
        break;
    }

    setBarCount(tooltipItems[0].raw);
    setColor(color);
    setCategoryDescription(text);
    return "";
  }

  useEffect(() => {
    setBarCount(0);
    setColor("");
    setCategoryDescription("Hover over each bar to see the number of individuals and what they are experiencing");
  }, [calcType]);

  return (
    <div className="h-[80%] mb-16">
      <div className={`border-2 flex content-center  items-center h-16 p-6 mb-6`}>
        <div className="h-10 w-10 border mr-4 flex text-center items-center content-center justify-center" style={{ borderColor: color }}>
          <p>{calcType === "Count" ? barCount : `${(barCount * 100).toFixed(0)}%`}</p>
        </div>
        <p className="text-sm text-gray-800">{categoryDescription}</p>
      </div>
      <div className="absolute mt-2 -ml-6 text-gray-500 hover:text-gray-700 cursor-pointer">
        <Toggle
          value={calcType === "Percentage"}
          setValue={(value) => (value ? setCalcType("Percentage") : setCalcType("Count"))}
          offIcon={customIcons.percentSign}
          onIcon={customIcons.user}
        />
      </div>
      <Bar
        options={{
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: "white",
          // aspectRatio: 4,
          plugins: {
            legend: {
              position: "top" as const,
              display: false,
            },
            datalabels: { display: false },
            title: {
              display: false,
              text: "Bar Chart",
            },
            tooltip: {
              callbacks: {
                //afterBody: generateTooltip,
                footer: generateTooltip,
              },
            },
          },
          scales: {
            y: {
              title: {
                text: calcType === "Percentage" ? "Percent of Respondents" : "Number of Respondents",
                display: true,
              },
              ticks: {
                format: {
                  style: calcType === "Percentage" ? "percent" : undefined,
                },
                stepSize: calcType === "Percentage" ? 0.25 : 5,
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
        }}
        data={data}
        className="mb-5"
      />
    </div>
  );
}
