import { Switch } from "@headlessui/react";

export default function Toggle(props: {
  value: boolean;
  setValue?: (value: boolean) => void;
  offIcon?: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref">>;
  onIcon?: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref">>;
  className?: string;
}) {
  const { value, setValue, offIcon: OffIcon, onIcon: OnIcon, className } = props;

  return (
    <Switch
      checked={value}
      onChange={setValue}
      className={`${className} group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent ${
        value ? "bg-gray-200" : "bg-gray-200"
      } transition-colors duration-200 ease-in-out focus:outline-none `}
    >
      {/* Icons for On and Off states */}
      <span className={`absolute inset-0 flex items-center justify-between text-white mx-0.5`}>
        {OnIcon && <OnIcon className={`h-4 w-4 text-gray-400`} />}
        {OffIcon && <OffIcon className={`h-4 w-4 text-gray-400`} />}
      </span>

      {/* Switch button */}
      <span
        aria-hidden="true"
        className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
          value ? "translate-x-5" : "translate-x-0"
        } flex justify-center items-center`}
      >
        {!value && OnIcon && <OnIcon className={`h-4 w-4 text-gray-600`} />}
        {value && OffIcon && <OffIcon className={`h-4 w-4 text-gray-600`} />}
      </span>
    </Switch>
  );
}
