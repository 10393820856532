import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Respondent, Survey, Dataset, Group, StrataJobTitle } from "../../types";
import RespondentDistribution from "./RespondentDistribution";
import RespondentCategory from "./RespondentCategory";
import Container from "../../components/Container";
import TrendChart from "../../components/TrendChart";
import colors from "tailwindcss/colors";
import GroupCategoryComparison from "./GroupCategoryComparison";
import Divider from "../../components/Divider";
import { LockClosedIcon, QuestionMarkCircleIcon } from "@heroicons/react/16/solid";
import { getLatestCompletedSurvey } from "../../hooks/helpers";
import Dropdown from "../../components/Dropdown";
import ReportHelp from "./ReportHelp";

export default function Report(props: { surveys: Survey[]; respondents: Respondent[]; groups: Group[]; strataJobTitles: StrataJobTitle[] }) {
  const { surveys, respondents, groups, strataJobTitles } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedGroupFilters, setSelectedGroupFilters] = useState<number[]>([]);
  const [selectedStrataJobTitleFilters, setSelectedStrataJobTitleFilters] = useState<number[]>([]);
  const [showCustomGroups, setShowCustomGroups] = useState<boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const surveyIdPath = parseInt(location.pathname.replace("/assessments/", ""));

  let surveyId: number | null = null;
  if (!isNaN(surveyIdPath)) {
    surveyId = surveyIdPath;
  }

  if (!surveyId) {
    const latestCompletedSurvey = getLatestCompletedSurvey(surveys);

    if (latestCompletedSurvey) {
      surveyId = latestCompletedSurvey.survey_id ?? null;
    }
  } else if (!surveys?.find((s) => s.survey_id === surveyId)) {
    navigate("/home");
  }

  function handleCheckboxChange(event: { target: { id: any; checked: any } }) {
    const { id, checked } = event.target;
    if (showCustomGroups) {
      if (checked) {
        // Add the checkbox to the selected list
        setSelectedGroupFilters((prevSelected) => [...prevSelected, parseInt(id)]);
      } else {
        // Remove the checkbox from the selected list
        setSelectedGroupFilters((prevSelected) => prevSelected.filter((item) => item !== parseInt(id)));
      }
    } else {
      if (checked) {
        // Add the checkbox to the selected list
        setSelectedStrataJobTitleFilters((prevSelected) => [...prevSelected, parseInt(id)]);
      } else {
        // Remove the checkbox from the selected list
        setSelectedStrataJobTitleFilters((prevSelected) => prevSelected.filter((item) => item !== parseInt(id)));
      }
    }
  }

  // cutoff values
  const UPPER_CUTOFF = 66.66; // above red because mental health value
  const LOWER_CUTOFF = 33.33; // below green because mental health value

  const survey = surveys.find((s) => s.survey_id === surveyId);

  const latestRespondents = respondents.filter((r) => r.survey_id === surveyId);

  // calculate datasets for stress category by Group
  let stressCategoryDatasets: Dataset[] = [
    {
      label: "At-risk",
      data: [],
      color: colors.red[400],
    },
    {
      label: "Impaired",
      data: [],
      color: colors.yellow[400],
    },
    {
      label: "Optimized",
      data: [],
      color: colors.green[400],
    },
    {
      label: "Disengaged",
      data: [],
      color: colors.gray[400],
    },
  ];

  // filter surveys that are not yet closed
  const completedSurveys = surveys.filter((s) => new Date(s.end_date) < new Date());

  // loop over completed surveys
  for (let i = 0; i < completedSurveys.length; i++) {
    // for each survey, get total counts of red, yellow, green
    let redCountForSurvey = 0;
    let yellowCountForSurvey = 0;
    let greenCountForSurvey = 0;
    let grayCountForSurvey = 0;
    const respondentsForSuvey = respondents.filter((r) => r.survey_id === completedSurveys[i].survey_id);
    for (let respondent of respondentsForSuvey) {
      if (showCustomGroups && selectedGroupFilters.length > 0 && !selectedGroupFilters.some((filter) => respondent.group_ids.includes(filter))) {
        continue; // skip for group filters
      }
      if (
        !showCustomGroups &&
        selectedStrataJobTitleFilters.length > 0 &&
        (!respondent.strata_job_title_id || !selectedStrataJobTitleFilters.includes(respondent.strata_job_title_id))
      ) {
        continue; // skip for strata job title filters
      }

      const mentalHealthValue = parseFloat(respondent.mental_health_value);
      if (mentalHealthValue > UPPER_CUTOFF) {
        redCountForSurvey++;
      } else if (mentalHealthValue > LOWER_CUTOFF) {
        yellowCountForSurvey++;
      } else if (mentalHealthValue > 10) {
        greenCountForSurvey++;
      } else {
        grayCountForSurvey++;
      }
    }
    stressCategoryDatasets[0].data.push({ value: redCountForSurvey, date: new Date(completedSurveys[i].start_date) }); // Red
    stressCategoryDatasets[1].data.push({ value: yellowCountForSurvey, date: new Date(completedSurveys[i].start_date) }); // Yellow
    stressCategoryDatasets[2].data.push({ value: greenCountForSurvey, date: new Date(completedSurveys[i].start_date) }); // Green
    stressCategoryDatasets[3].data.push({ value: grayCountForSurvey, date: new Date(completedSurveys[i].start_date) }); // Gray
  }

  // broken down by groups (grouped bar)
  const latestCompletedGroupCounts: { name: string; red: number; yellow: number; green: number; gray: number; taken: number }[] = [];

  // broken down by strata job titles (grouped bar)
  const latestCompletedStrataJobTitleCounts: {
    name: string;
    red: number;
    yellow: number;
    green: number;
    gray: number;
    taken: number;
  }[] = [];

  if (latestRespondents) {
    groups.forEach((group) => {
      const latestRespondentsInGroup = latestRespondents.filter((lr) => lr.group_ids && lr.group_ids.includes(group.group_id));
      let redCount = 0;
      let yellowCount = 0;
      let greenCount = 0;
      let grayCount = 0;
      let groupTakenCount = 0;

      latestRespondentsInGroup.forEach((r) => {
        groupTakenCount++;
        const mentalHealthValue = parseFloat(r.mental_health_value);
        if (mentalHealthValue > UPPER_CUTOFF) {
          redCount++;
        } else if (mentalHealthValue > LOWER_CUTOFF) {
          yellowCount++;
        } else if (mentalHealthValue > 10) {
          greenCount++;
        } else {
          grayCount++;
        }
      });

      latestCompletedGroupCounts.push({
        name: group.group_name,
        red: redCount,
        yellow: yellowCount,
        green: greenCount,
        gray: grayCount,
        taken: groupTakenCount,
      });
    });

    for (const strataJobTitle of strataJobTitles) {
      const latestRespondentsWithStrataJobTitle = latestRespondents.filter((res) => res.strata_job_title_id === strataJobTitle.strata_job_title_id);
      let redCount = 0;
      let yellowCount = 0;
      let greenCount = 0;
      let grayCount = 0;
      let takenCount = 0;

      for (const respondent of latestRespondentsWithStrataJobTitle) {
        takenCount++;
        const mentalHealthValue = parseFloat(respondent.mental_health_value);
        if (mentalHealthValue > UPPER_CUTOFF) {
          redCount++;
        } else if (mentalHealthValue > LOWER_CUTOFF) {
          yellowCount++;
        } else if (mentalHealthValue > 10) {
          greenCount++;
        } else {
          grayCount++;
        }
      }
      latestCompletedStrataJobTitleCounts.push({
        name: strataJobTitle.strata_job_title_name,
        red: redCount,
        yellow: yellowCount,
        green: greenCount,
        gray: grayCount,
        taken: takenCount,
      });
    }
  }

  // group impaired
  let groupImpairedTrendDatasets: Dataset[] = groups.map((group) => {
    return { label: group.group_name ?? "Not Found", data: [] };
  });

  // impaired counts for each month
  for (let i = 0; i < completedSurveys.length; i++) {
    const groupsObject: any = {};
    const respondentsForSuvey = respondents.filter((r) => r.survey_id === completedSurveys[i].survey_id);
    groups.forEach((group) => {
      const respondentsInGroup = respondentsForSuvey.filter((lr) => lr.group_ids && lr.group_ids.includes(group.group_id));
      let redCount = 0;
      let yellowCount = 0;
      respondentsInGroup.forEach((r) => {
        const mentalHealthValue = parseFloat(r.mental_health_value);
        if (mentalHealthValue > UPPER_CUTOFF) {
          redCount++;
        } else if (mentalHealthValue > LOWER_CUTOFF) {
          yellowCount++;
        }
      });

      const dataset = groupImpairedTrendDatasets.find((ds) => ds.label === group.group_name);
      if (dataset) {
        dataset.data[i] = { value: redCount + yellowCount, date: new Date(completedSurveys[i].start_date) };
      }
    });

    Object.values(groupsObject).forEach((g: any) => {
      const dataset = groupImpairedTrendDatasets.find((d) => d.label === g.name);
      if (dataset) {
        dataset.data[i] = { value: g.count, date: new Date(completedSurveys[i].start_date) };
      }
    });
  }

  // strata job title impaired
  let strataJobTitleImpairedTrendDatasets: Dataset[] = strataJobTitles.map((jobTitle) => {
    return { label: jobTitle.strata_job_title_name ?? "Not Found", data: [] };
  });

  // impaired counts for each month
  for (let i = 0; i < completedSurveys.length; i++) {
    const strataJobTitlesObject: any = {};
    const respondentsForSuvey = respondents.filter((r) => r.survey_id === completedSurveys[i].survey_id);

    for (const strataJobTitle of strataJobTitles) {
      const respondentsWithStrataJobTitle = respondentsForSuvey.filter((res) => res.strata_job_title_id === strataJobTitle.strata_job_title_id);
      let redCount = 0;
      let yellowCount = 0;
      respondentsWithStrataJobTitle.forEach((r) => {
        const mentalHealthValue = parseFloat(r.mental_health_value);
        if (mentalHealthValue > UPPER_CUTOFF) {
          redCount++;
        } else if (mentalHealthValue > LOWER_CUTOFF) {
          yellowCount++;
        }
      });

      const dataset = strataJobTitleImpairedTrendDatasets.find((ds) => ds.label === strataJobTitle.strata_job_title_name);
      if (dataset) {
        dataset.data[i] = { value: redCount + yellowCount, date: new Date(completedSurveys[i].start_date) };
      }
    }

    Object.values(strataJobTitlesObject).forEach((jt: any) => {
      const dataset = strataJobTitleImpairedTrendDatasets.find((d) => d.label === jt.name);
      if (dataset) {
        dataset.data[i] = { value: jt.count, date: new Date(completedSurveys[i].start_date) };
      }
    });
  }

  const groupDropdownOptions = ["Custom Groups", "Clinical Groups"];

  if (!surveyId || !survey) {
    return (
      <div>
        <div className="">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Mental Health Breakdown</h2>
        </div>
        <div className="flex items-center justify-center h-96">
          <div className="flex flex-col justify-center content-center items-center">
            <LockClosedIcon className="h-14 w-14 mb-4 bg-yellow-400 rounded-full p-4" />
            <h1 className="text-xl font-bold">No Breakdown Results - Yet</h1>
            <p className="mt-4 text-gray-500 text-center">Come back after your first assessment is complete</p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="">
        <div className="mb-6">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Mental Health Breakdown</h2>
        </div>
        <RespondentCategory
          responseRate={survey.response_rate ? parseFloat(survey.response_rate) : 0}
          green={survey.green_count ?? 0}
          yellow={survey.yellow_count ?? 0}
          red={survey.red_count ?? 0}
          gray={survey.disengaged_count ?? 0}
          totalRecipients={survey.total_recipients}
        />

        <ReportHelp openHelp={openHelp} setOpenHelp={setOpenHelp} />

        <div className="mt-8 mb-8">
          <Divider heading={""} />
        </div>

        <div className="flex items-center mb-4">
          <div className="mr-4">
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">How is Your Team Experiencing Stress</h2>
          </div>
          <div className="relative group">
            <QuestionMarkCircleIcon className="h-6 w-6 cursor-pointer" onClick={() => setOpenHelp(!openHelp)} />

            <div className="absolute right-1/2 transform translate-x-1/2 -top-[70px] hidden w-48 p-3 text-sm text-white bg-black rounded-lg group-hover:block">
              Learn more about what this measures
              <div className="absolute bottom-[-6px] left-1/2 transform -translate-x-1/2 w-3 h-3 bg-black rotate-45"></div>
            </div>
          </div>
        </div>

        <Container title="" className="col-span-3 h-96 mb-4">
          <RespondentDistribution respondents={latestRespondents} />
        </Container>

        <div>
          <div className="mb-4 mt-12">
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Group Stress Breakdown</h2>
          </div>

          <Container title="" className="col-span-3 mb-4 h-96">
            {latestCompletedGroupCounts.length > 0 && (
              <Dropdown
                options={groupDropdownOptions}
                selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
                className="mb-5 mr-2"
              />
            )}
            <GroupCategoryComparison groupCounts={showCustomGroups ? latestCompletedGroupCounts : latestCompletedStrataJobTitleCounts} />
          </Container>
        </div>

        <div className="mb-4 mt-12">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Stress Trend Over Time</h2>
        </div>

        <div className="mb-6 grid grid-cols-5 gap-4">
          <Container title="Number in Red, Yellow, and Green Over Time" className={`lg:col-span-4 md:col-span-3 col-span-5 w-full h-96 relative`}>
            <TrendChart
              datasets={stressCategoryDatasets}
              stepSize={1}
              suggestedMin={0}
              suggestedMax={5}
              calcType="add"
              yAxisUnits="Respondents"
              showPercentageToggle={true}
            />
          </Container>

          <Container title="Filter" className="lg:col-span-1 md:col-span-2 col-span-5 w-full h-96 lg:px-5">
            {latestCompletedGroupCounts.length > 0 && (
              <Dropdown
                options={groupDropdownOptions}
                selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
                className="mb-2 mt-2 z-20"
              />
            )}
            <div className="h-[265px] overflow-scroll pl-1">
              {showCustomGroups
                ? groups.map((group) => {
                    return (
                      <fieldset key={group.group_id} className="mb-2">
                        <legend className="sr-only">{group.group_name}</legend>
                        <div className="space-y-5">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id={group.group_id?.toString()}
                                aria-describedby={`${group.group_name}`}
                                name={group.group_name}
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-strataBlue focus:ring-strataBlue"
                                onChange={(e) => handleCheckboxChange(e)}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label htmlFor={group.group_id?.toString()} className="font-medium text-gray-900">
                                {group.group_name}
                              </label>{" "}
                              <span id={`${group.group_name}-description`} className="text-gray-500">
                                <span className="sr-only">{group.group_name}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    );
                  })
                : strataJobTitles.map((strataJobTitle) => (
                    <fieldset key={strataJobTitle.strata_job_title_id} className="mt-4 mb-2">
                      <legend className="sr-only">{strataJobTitle.strata_job_title_name}</legend>
                      <div className="space-y-5">
                        <div className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              id={strataJobTitle.strata_job_title_id?.toString()}
                              aria-describedby={`${strataJobTitle.strata_job_title_name}`}
                              name={strataJobTitle.strata_job_title_name}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-strataBlue focus:ring-strataBlue"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor={strataJobTitle.strata_job_title_id?.toString()} className="font-medium text-gray-900">
                              {strataJobTitle.strata_job_title_name}
                            </label>{" "}
                            <span id={`${strataJobTitle.strata_job_title_name}-description`} className="text-gray-500">
                              <span className="sr-only">{strataJobTitle.strata_job_title_name}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  ))}
            </div>
          </Container>
        </div>

        <div>
          <div className="mb-4 mt-12">
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Groups Experiencing the Highest Stress Levels</h2>
          </div>
          <Container title="Groups in the Red and Yellow" className="lg:col-span-4 md:col-span-3 col-span-5 w-full h-96 relative">
            {latestCompletedGroupCounts.length > 0 && (
              <Dropdown
                options={groupDropdownOptions}
                selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
                className="mb-2 mt-2 z-20 flex"
              />
            )}
            <TrendChart
              datasets={showCustomGroups ? groupImpairedTrendDatasets : strataJobTitleImpairedTrendDatasets}
              suggestedMax={5}
              suggestedMin={0}
              calcType="add"
              height={"80%"}
              yAxisUnits="Respondents"
              showPercentageToggle={true}
            />
          </Container>
        </div>
      </div>
    );
  }
}
